import React, { Component } from "react"
import { Link } from "gatsby"
import Sticky from "react-stickynode"
import logoKalgen from "../images/kalgen-logo.jpeg"

class Header4 extends Component {
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".navicon")
    var nav = document.querySelector(".header-nav")

    function toggleFunc() {
      btn.classList.toggle("open")
      nav.classList.toggle("show")
    }

    btn.addEventListener("click", toggleFunc)

    // Sidenav li open close
    var navUl = [].slice.call(
      document.querySelectorAll(".header-nav > ul > li")
    )
    for (var y = 0; y < navUl.length; y++) {
      navUl[y].addEventListener("click", function () {
        checkLi(this)
      })
    }

    function checkLi(current) {
      const active = current.classList.contains("open")
      navUl.forEach(el => el.classList.remove("open"))
      //current.classList.add('open');

      if (active) {
        current.classList.remove("open")
        console.log("active")
      } else {
        current.classList.add("open")
        console.log("close")
      }
    }
  }

  render() {
    return (
      <>
        <Sticky innerZ={999} enabled={true}>
          <header class="site-header mo-left header navstyle2">
            <div class="sticky-header main-bar-wraper header-curve navbar-expand-lg">
              <div class="main-bar clearfix bg-primary">
                <div class="container clearfix">
                  <div class="logo-header mostion">
                    <Link to="/">
                      <img src={logoKalgen} alt="Logo Kalgen" />
                    </Link>
                  </div>

                  <button
                    class="navbar-toggler collapsed navicon justify-content-end"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNavDropdown"
                    aria-controls="navbarNavDropdown"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>

                  <div
                    class="header-nav navbar-collapse collapse justify-content-end"
                    id="navbarNavDropdown"
                  >
                    <div class="logo-header d-md-block d-lg-none"></div>
                    <ul class="nav navbar-nav">
                      <li
                        class={
                          this.props.indexPage
                            ? "active has-mega-menu homedemo"
                            : "has-mega-menu homedemo"
                        }
                      >
                        <Link to="/">Home</Link>
                      </li>
                      <li class="has-mega-menu">
                        {" "}
                        <Link to={"/#About-Us"}>About Us </Link>
                      </li>
                      <li class={this.props.productsPage ? "active" : ""}>
                        <Link to={"#g"}>
                          Product<i class="fa fa-chevron-down"></i>
                        </Link>
                        <ul class="sub-menu">
                          <li className="">
                            <Link to="/products/hpv-xpress-matrix">
                              HPV XpressMatrix™ Kit
                            </Link>
                          </li>
                          <li className="">
                            <Link to="/products/sars-cov-2-real-time">
                              Elva Diagnostic SARS-CoV-2 Real-time RT PCR Kit
                            </Link>
                          </li>
                          <li className="">
                            <Link to="/products/sars-cov-2-saliva-nucleic">
                              Elva Diagnostic SARS-CoV-2 Saliva Nucleic Acid
                              Test Kit
                            </Link>
                          </li>
                          <li className="">
                            <Link to="/products/ampfire-hpv-screening">
                              AmpFire HPV Screening HR 16/18 Kit
                            </Link>
                          </li>
                          <li className="">
                            <Link to="/products/indigen-real-time">
                              INDIGEN MTB/NTM/DR-TB Real Time PCR Kit Gen. 2
                            </Link>
                          </li>
                          <li className="">
                            <Link to="/products/diago-t790m">
                              DIAGO T790M Mutation Detection Kit
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li class={this.props.servicesPage ? "active" : ""}>
                        <Link to={"#g"}>
                          Service<i class="fa fa-chevron-down"></i>
                        </Link>
                        <ul class="sub-menu">
                          <li>
                            <Link to="/services/hpv-genotyping">
                              HPV DNA Genotyping Test
                            </Link>
                          </li>
                          <li>
                            <Link to="/services/hpv-highrisk">
                              HPV DNA High Risk Test
                            </Link>
                          </li>
                          <li>
                            <Link to="/services/liquid-based-cytology">
                              Liquid Based Cytology
                            </Link>
                          </li>
                          <li>
                            <Link to="/services/co-testing">Co-Testing</Link>
                          </li>
                        </ul>
                      </li>
                      <li class="has-mega-menu">
                        {" "}
                        <Link to={"/#Partnership"}>Partner</Link>
                      </li>

                      <li
                        class={
                          this.props.ContactUs
                            ? "active has-mega-menu homedemo"
                            : "has-mega-menu homedemo"
                        }
                      >
                        <Link to={"/contact-us"}>Contact</Link>
                      </li>
                    </ul>
                    <div class="dlab-social-icon ind-social"></div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </Sticky>
      </>
    )
  }
}
export default Header4
