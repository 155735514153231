import React, { Component } from "react"
import { Link } from "gatsby"

// const bg = require('../../images/background/bg3.png')
// style="background-image:url(images/background/bg3.png); background-size: cover;"

class Footer4 extends Component {
  render() {
    return (
      <>
        <footer class="site-footer">
          <div class="footer-top">
            <div class="container">
              <div class="row">
                <div class="col-md-3 col-5 col-xl-2 col-lg-2 col-sm-6 footer-col-4">
                  <div class="widget widget_services border-0">
                    <h5 class="footer-title text-white">Company</h5>
                    <ul className="nav-text">
                      <li>
                        <Link
                          className="nav-text"
                          to="/"
                          style={{ color: "white" }}
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-text"
                          to={"/#About-Us"}
                          style={{ color: "white" }}
                        >
                          About Us
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-text"
                          to={"/#Our-Products"}
                          style={{ color: "white" }}
                        >
                          Product
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-text"
                          to={"/#Our-Services"}
                          style={{ color: "white" }}
                        >
                          Service
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-text"
                          to={"/#Partnership"}
                          style={{ color: "white" }}
                        >
                          Partner
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="nav-text"
                          to="/contact-us"
                          style={{ color: "white" }}
                        >
                          Contact Us
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-3 col-7 col-xl-2 col-lg-3 col-sm-6 footer-col-4">
                  {/* <div class="widget widget_services border-0">
                    <h5 class="footer-title text-white">Useful Link</h5>
                    <ul>
                      <li>
                        <Link to="/help-desk">Help Desk </Link>
                      </li>
                      <li>
                        <Link to="/shop">Shop</Link>
                      </li>
                      <li>
                        <Link to="/contact-1">Contact</Link>
                      </li>
                      <li>
                        <Link to="/portfolio-details">Portfolio</Link>
                      </li>
                      <li>
                        <Link to="/team-1">Team</Link>
                      </li>
                    </ul>
                  </div> */}
                </div>
                <div class="col-md-6 col-xl-4 col-lg-3 col-sm-6 footer-col-4">
                  <div class="widget widget_getintuch">
                    <h5 class="footer-title text-white">Contact us</h5>
                    <ul style={{ color: "white" }}>
                      <li>
                        <i class="ti-location-pin"></i>
                        <strong style={{ color: "white" }}>address</strong>{" "}
                        KALBE BUSINESS INNOVATION CENTER Jl. Pulogadung No.23,
                        3rd Floor, Jatinegara, Cakung, Jakarta 13930 Indonesia
                      </li>
                      <li>
                        <i class="ti-mobile"></i>
                        <strong style={{ color: "white" }}>phone</strong>+62 21
                        80604202
                      </li>
                      <li>
                        <i class="ti-email"></i>
                        <strong style={{ color: "white" }}>email</strong>
                        info@kalgendna.co.id
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-12 col-xl-4 col-lg-4 col-sm-6 footer-col-4 ">
                  <div class="widget">
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>

                    {/* <h5 class="footer-title text-white">
                      Subscribe To Our Newsletter
                    </h5> */}
                    {/* <p class="text-capitalize m-b20">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry has been the industry's standard
                      dummy text ever since the..
                    </p> */}
                    {/* <div class="subscribe-form m-b20">
                      <form
                        class="dzSubscribe"
                        action="script/mailchamp.php"
                        method="post"
                      >
                        <div class="dzSubscribeMsg"></div>
                        <div class="input-group">
                          <input
                            name="dzEmail"
                            required="required"
                            class="form-control"
                            placeholder="Your Email Id"
                            type="email"
                          />
                          <span class="input-group-btn">
                            <button
                              name="submit"
                              value="Submit"
                              type="submit"
                              class="site-button radius-xl btnhover16"
                            >
                              Subscribe
                            </button>
                          </span>
                        </div>
                      </form>
                    </div> */}
                    <ul class="list-inline m-a0">
                      {/* <li>
                        <Link to="#" class="site-button facebook circle ">
                          <i class="fa fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" class="site-button google-plus circle ">
                          <i class="fa fa-google-plus"></i>
                        </Link>
                      </li> */}
                      <li>
                        <a
                          href="https://id.linkedin.com/company/pt-kalgen-dna"
                          class="site-button linkedin circle "
                          target="_blank"
                          style={{ backgroundColor: "#17aab5" }}
                        >
                          <i class="fa fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/kalgen.dna/"
                          class="site-button instagram circle "
                          target="_blank"
                          style={{ backgroundColor: "#17aab5" }}
                        >
                          <i class="fa fa-instagram"></i>
                        </a>
                      </li>
                      {/* <li>
                        <Link to="#" class="site-button twitter circle ">
                          <i class="fa fa-twitter"></i>
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="footer-bottom">
            <div class="container">
              <div class="row">
                <div class="col-md-6 col-sm-6 text-left ">
                  {" "}
                  <span>Copyright © 2021 DexignZone</span>{" "}
                </div>
                <div class="col-md-6 col-sm-6 text-right ">
                  <div class="widget-link ">
                    <ul>
                      <li>
                        <Link to="/about-2"> About</Link>
                      </li>
                      <li>
                        <Link to="/help-desk"> Help Desk</Link>
                      </li>
                      <li>
                        <Link to="/privacy-policy"> Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </footer>
      </>
    )
  }
}
export default Footer4
